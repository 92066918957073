<template lang="pug">
div.w-100.text-left.white
  h4.text-left.pt-2 {{ $t('documentsVerification') }}
  v-tabs(
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline")
    v-tabs-slider(color="primary")
    div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id" )
      v-tab(:to="tab.link" v-if="tab.checkAccess()").p-2 {{$t(tab.label)}}
  b-card(v-if="checkAccess('menuItem-documentsVerification')" no-body)
    router-view

</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      checkAccess,
      tabs: [
        { id: 1, label: 'model-SailorPassport', link: { name: 'sailor-passport-verification' }, checkAccess: () => checkAccess('sailorPassportVerification') },
        { id: 2, label: 'internationalPassport.name', link: { name: 'international-passport-verification' }, checkAccess: () => checkAccess('internationalPassportVerification') },
        { id: 3, label: 'education', link: { name: 'education-verification' }, checkAccess: () => checkAccess('educationVerification') },
        { id: 4, label: 'qualificationDocs', link: { name: 'qualification-verification' }, checkAccess: () => checkAccess('qualificationVerification') },
        { id: 5, label: 'shipownerLicense', link: { name: 'navigator-certificate-verification' }, checkAccess: () => checkAccess('navigatorCertificateVerification') },
        { id: 6, label: 'recordBook', link: { name: 'service-record-verification' }, checkAccess: () => checkAccess('recordBookVerification') },
        { id: 7, label: 'lineServiceRecord', link: { name: 'line-service-record-verification' }, checkAccess: () => checkAccess('lineRecordBookVerification') },
        { id: 8, label: 'model-MedicalCertificate', link: { name: 'medical-verification' }, checkAccess: () => checkAccess('medicalVerification') },
        { id: 9, label: 'civilPassport.name', link: { name: 'civil-passport-verification' }, checkAccess: () => checkAccess('civilPassportVerification') }
      ]
    }
  },
  beforeMount () {
    this.$store.commit('setActivePage', { name: 'documentVerification', access: checkAccess('menuItem-documentsVerification') })
    this.gdirection()
  },
  methods: {
    ...mapActions(['gdirection'])
  }
}
</script>
